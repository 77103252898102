<template>
  <div class="container px-8 mb-6">
    <p class="d-flex justify-center text-sm-h2 text-h4">Авторизация</p>
    <form>
      <v-text-field
        v-model="email"
        :error-messages="emailErrors"
        label="Укажите e-mail"
        class="my-8"
        required
        @input="$v.email.$touch()"
        @blur="$v.email.$touch()"
      ></v-text-field>

      <v-text-field
        v-model="password"
        :error-messages="passwordErrors"
        label="Введите пароль"
        class="mb-12"
        required
        @input="$v.password.$touch()"
        @blur="$v.password.$touch()"
      ></v-text-field>

      <v-breadcrumbs :items="bcItems" class="d-flex justify-end pr-6 mt-n6 mb-6 text-sm-h4 text-h6"></v-breadcrumbs>

      <div class="d-flex justify-space-between">
        <v-btn color="red" dark class="mr-4 text-uppercase text-subtitle-2" @click="login">Войти</v-btn>
        <v-btn dark :to="{name: 'Register'}" class="text-uppercase text-subtitle-2">Зарегистрироваться</v-btn>
      </div>

    </form>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, minLength, email } from 'vuelidate/lib/validators'

export default {
  mixins: [validationMixin],
  validations: {
    email: { required, email },
    password: { required, minLength: minLength(8) }
  },
  data:() => ({
      email: '',
      password: '',
      forgotPassword: false,
      bcItems: [
        {
          text: 'Забыли пароль?',
          disabled: false,
          href: '#',
        }
      ],
      register: false
  }),
  methods: {
    login() {
      this.$store.state.users.forEach(user => {
        if (user.userPassword === this.password && user.userEmail === this.email) {
          this.$store.commit('loginUser', user);
          return this.$router.push({ name:'Home' });
        }
        return false;
      });
    }
  },
  computed: {
    emailErrors () {
      const errors = [];
      if (!this.$v.email.$dirty) return errors;
      !this.$v.email.email && errors.push('Пожалуйста, укажите корректный адрес электронной почты');
      !this.$v.email.required && errors.push('Пожалуйста, введите адрес электронной почты');
      return errors;
    },
    passwordErrors () {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.required && errors.push('Пожалуйста, введите пароль');
      return errors;
    },
  }
}
</script>